/** @format */

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as R from "ramda";
import { Button } from "antd";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectFlip,
  Pagination,
  Navigation,
  EffectCards,
  EffectCreative,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-flip";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import "swiper/css/effect-creative";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import "utils/i18n";
import {
  apiBase,
  clientId,
  horizonDomain,
  REGION_CHINA,
  getRegion,
  eqrcodeVersion,
} from "constants/env";
import {
  camelToSnack,
  objToQuery,
  getStorageToken,
  targetSolution,
  auid,
} from "utils/stringUtil";
import LoginLogo from "assets/login/LoginLogo";
import Banner1 from "assets/login/banner1";
import Banner2 from "assets/login/banner2";
import Banner3 from "assets/login/banner3";
import Banner10 from "assets/login/banner10";
import hpLogo from "assets/hpLogo.svg";
import SPLogo from "assets/SPLogo";
import eqrcode from "assets/eqrcode.png";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(
    from 186.86deg at 50% 50%,
    #feffff -57.71deg,
    #9bdeff 6.07deg,
    #feffff 302.29deg,
    #9bdeff 366.07deg
  );
`;

const StyledGrid = styled.div`
  width: 100%;
  height: calc(100% - 56px);
  display: grid;
  justify-items: center;
  align-content: center;
`;

const LoginContainer = styled.div`
  width: 646px;
  height: 380px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 340px 306px;
  filter: drop-shadow(0px 4px 16px rgba(67, 167, 216, 0.4));
  border-radius: 8px;
  overflow: hidden;
`;

const StyledLogin = styled.div`
  background: #fff;
  text-align: center;
`;

const StyledBanner = styled.div`
  .swiper {
    width: 307px;
    height: 380px;
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;

    /* Center slide text vertically */
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .swiper-pagination-bullet {
    background: #ffffff;
    opacity: 0.5;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }
`;

const StyledBannerItem = styled.div`
  background: linear-gradient(
    216.66deg,
    #46ceed 16.11%,
    #229bff 72.25%,
    #6666ff 103.46%
  );
`;

const StyleBanner2Item = styled.div``;

const StyleBanner3Item = styled.div`
  background: linear-gradient(
    213.12deg,
    #6666ff 0%,
    #229bff 48.49%,
    #64e4f5 88.84%
  );
`;

const JumpHPIDButton = styled(Button)`
  font-size: 14px;
  border: none;
  cursor: pointer;
  text-align: center;
  width: 244px;
  min-width: 150px;
  height: 36px;
  color: #fff;
  background: #229bff;
  margin-top: 32px;
  border-radius: 4px;
  :hover,
  :focus,
  :active {
    color: #fff;
    background: #45a9fb;
  }
  :disabled {
    background: linear-gradient(0deg, #d8d8d8, #d8d8d8), #0096d6;
    color: #fff;
  }
`;

const StyledFaq = styled.div`
  margin-top: 24px;
  color: #bdbdbd;
  font-size: 12px;
  a {
    text-decoration: none;
    color: #229bff;
  }
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  height: 55px;
  line-height: 55px;
  padding: 0 40px;
  a {
    font-size: 12px;
    color: #404040;
    text-decoration: none;
  }
`;

function Login(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [loginParam, setLoginParam] = useState<any>({});
  const { identity } = R;

  useEffect(() => {
    setLoginParam({
      response_type: "code",
      clientId,
      state: "isv_state",
      redirectUri: `${window.location.origin}/oauth`,
      invitation_code: invitationCode || null,
      locale: "en_US",
      // email: invitationCode ? encodeEmail : null,
      acr_values:
        window.location.host === `console.${horizonDomain}`
          ? "urn:hpbp:hpid:onehp"
          : "urn:hpbp:hpid",
    });
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const invitationCode = searchParams.get("invitationCode");

  window.addEventListener("pageshow", (e) => {
    if (e.persisted) {
      setLoginLoading(false);
    }
  });

  const JumpHPID = () => {
    const params = {
      response_type: "code",
      clientId,
      state: "isv_state",
      redirectUri: `${window.location.origin}/oauth`,
      invitation_code: invitationCode || null,
      locale: "en_US",
      // email: invitationCode ? encodeEmail : null,
      acr_values:
        window.location.host === `console.${horizonDomain}`
          ? "urn:hpbp:hpid:onehp"
          : "urn:hpbp:hpid",
    };

    window.location.href = `${apiBase}/oauth/v1/auth?${objToQuery(
      camelToSnack,
      params
    )}`;
  };

  const onFinish = () => {
    setLoginLoading(true);
    //  JumpHPID();
  };

  const isEQRcode =
    typeof eqrcodeVersion === "string"
      ? eqrcodeVersion === "true"
      : eqrcodeVersion;

  return (
    <Container>
      <Helmet>
        <title>{isEQRcode ? "Premium Service" : "Horizon"}</title>
      </Helmet>
      <StyledGrid>
        <LoginContainer>
          <StyledLogin>
            {isEQRcode ? (
              <div>
                <SPLogo
                  style={{ marginTop: "88px", marginBottom: "20px" }}
                ></SPLogo>
              </div>
            ) : (
              <LoginLogo style={{ marginTop: "88px" }} />
            )}

            <span
              //   href={`${apiBase}/oauth/v1/auth?${objToQuery(
              //     camelToSnack,
              //     loginParam
              //   )}`}
              onClick={() => {
                const authenticated = getStorageToken();
                // if (authenticated) {
                //   const path = targetSolution();
                //   window.location.href = `${window.location.origin}${path}`;
                // } else {
                window.location.href = `${apiBase}/oauth/v1/auth?${objToQuery(
                  camelToSnack,
                  loginParam
                )}`;
                // }
              }}
            >
              <JumpHPIDButton
                type="primary"
                loading={loginLoading}
                data-auid="AUID_Login_Submit"
                onClick={onFinish}
              >
                {t("login.loginButton")}
              </JumpHPIDButton>
            </span>
            {!isEQRcode && (
              <StyledFaq>
                {`${t("login.description")} `}
                <a
                  href="https://pages.github.azc.ext.hp.com/hp-business-platform/home.io/faq"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("login.faq")}
                </a>
              </StyledFaq>
            )}
          </StyledLogin>

          <StyledBanner>
            <div>
              {isEQRcode ? (
                <Banner10 />
              ) : (
                <Swiper
                  modules={[Pagination, EffectCreative, Navigation]}
                  spaceBetween={0}
                  grabCursor={true}
                  // cubeEffect={{
                  //   shadow: true,
                  //   slideShadows: true,
                  //   shadowOffset: 20,
                  //   shadowScale: 0.94,
                  // }}
                  // autoplay={{
                  //   delay: 6000,
                  //   disableOnInteraction: false,
                  // }}
                  speed={2000}
                  effect={"creative"}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  creativeEffect={{
                    prev: {
                      shadow: true,
                      translate: [0, 0, -400],
                    },
                    next: {
                      translate: ["100%", 0, 0],
                    },
                  }}
                  onSlideChange={() => {}}
                >
                  <SwiperSlide>
                    <StyledBannerItem>
                      <Banner1 />
                    </StyledBannerItem>
                  </SwiperSlide>
                  <SwiperSlide>
                    <StyleBanner2Item>
                      <Banner2 />
                    </StyleBanner2Item>
                  </SwiperSlide>
                  <SwiperSlide>
                    <StyleBanner3Item>
                      <Banner3 />
                    </StyleBanner3Item>
                  </SwiperSlide>
                </Swiper>
              )}
            </div>
          </StyledBanner>
        </LoginContainer>
      </StyledGrid>

      <StyledFooter>
        <div>
          <img src={hpLogo} className="App-logo" alt="logo" />
        </div>
        {REGION_CHINA === getRegion() && (
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            rel="noreferrer"
          >
            沪ICP备15039448号-14
          </a>
        )}
      </StyledFooter>
    </Container>
  );
}

export default Login;
