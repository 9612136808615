/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  style?: {};
}

const Mobius = forwardRef(
  ({ width = 24, height = 24, style, fill, ...restProps }: IconProps, ref) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M12 0L22.3923 5.99792V17.9938L12 23.9917L1.6077 17.9938V5.99792L12 0Z"
        fill="url(#paint0_linear_44_4000)"
      />
      <path
        d="M4.77276 7.82483L12 3.65362L19.2272 7.82483V16.1666L12 20.3378L4.77276 16.1666V7.82483Z"
        stroke="url(#paint1_angular_44_4000)"
        strokeOpacity="0.6"
        strokeWidth="2"
      />
      <path
        d="M5.5 12.7455L12 8.99683L18.5 12.7455L12 16.4942L5.5 12.7455Z"
        fill="white"
      />
      <path
        d="M12 24L11.9976 16.4985L5.5 12.7456L5.5024 20.2472L12 24Z"
        fill="url(#paint2_linear_44_4000)"
      />
      <path
        d="M12 24L12.0024 16.4985L18.5 12.7456L18.4976 20.2472L12 24Z"
        fill="url(#paint3_linear_44_4000)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_44_4000"
          x1="18"
          y1="5.88066e-07"
          x2="8.00728"
          y2="21.0035"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6666FF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <radialGradient
          id="paint1_angular_44_4000"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12 11.9957) rotate(115.004) scale(8.28035 8.28322)"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_44_4000"
          x1="9.5"
          y1="15.5"
          x2="11.9312"
          y2="20.6037"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75C1FF" />
          <stop offset="1" stopColor="#41A3F3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_44_4000"
          x1="15.25"
          y1="12.7456"
          x2="15.25"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54B2FF" />
          <stop offset="1" stopColor="#EFF7FE" />
        </linearGradient>
      </defs>
    </svg>
  )
);

export default Mobius;
