/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

import { ChildProjectName } from "./interface";

import { routerName } from "constants/env";

export const childProjectName: ChildProjectName = {
  console: "Developer Portal",
  insights: "Insights",
  mobius: "Model Studio",
};

export const solutionPath: { console: string; insights: string } = {
  console: "console",
  insights: routerName,
};

export const solutionURL: any = {
  "Developer Portal": "console",
  Insights: "insights/projects",
  "Model Studio": "mobius",
};
